import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/banner/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          addData(ctx,queryParams){
              var data = JSON.stringify(queryParams);
              
              return new Promise((resolve, reject) => {
                axios
                  .post('/banner/v1/add',queryParams)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
  
          },
          updateData(ctx,queryParams){
           
              var data = JSON.stringify(queryParams);
              
              return new Promise((resolve, reject) => {
                axios
                  .put('/banner/v1/update/'+queryParams.id,data,{
                    headers:{
                      'Content-Type': 'multipart/form-data',
                      'dataType':'json'
                    }
                  })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
  
          },
          deleteData(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .delete('/banner/v1/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
  
        },
        uploadimages(ctx,queryParams){
          return new Promise((resolve, reject) => {
            axios
              .post('/banner/v1/image/add/'+queryParams.id,queryParams.formData)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
    
        },
        fetchImageData(ctx,queryParams){
         
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/banner/v1/image/getall/'+queryParams.id,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateImageData(ctx,queryParams){
           
          var data = JSON.stringify(queryParams);
          
          return new Promise((resolve, reject) => {
            axios
              .put('/banner/v1/image/update/'+queryParams.id,data,{
                headers:{
                  'Content-Type': 'multipart/form-data',
                  'dataType':'json'
                }
              })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      },
      updateOrdering(ctx, queryParams) {
          var data = JSON.stringify(queryParams);
          
          return new Promise((resolve, reject) => {
            axios
              .put('/banner/v1/image/updateOrdering',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
      },
      fetchImageDelete(ctx,id){
        return new Promise((resolve, reject) => {
          axios
            .delete('/banner/v1/image/delete/'+id)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
  
      }
    }
}